import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
@Component({
  selector: 'rcp-paginator-skeleton',
  standalone: true,
  imports: [SkeletonModule, CommonModule],
  templateUrl: './paginator-skeleton.component.html',
  styleUrl: './paginator-skeleton.component.scss',
})
export class PaginatorSkeletonComponent {}
